import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { CandyMachineAccount } from "./candy-machine";
import { CircularProgress } from "@material-ui/core";
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react";
import { useEffect, useState, useRef } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  findGatewayToken,
  getGatewayTokenAddressForOwnerAndGatekeeperNetwork,
  onGatewayTokenChange,
  removeAccountChangeListener,
} from "@identity.com/solana-gateway-ts";

export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`; // add your own styles here

export const MintButton = ({
  onMint,
  candyMachine,
  isMinting,
  setIsMinting,
  isActive,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  isMinting: boolean;
  setIsMinting: (val: boolean) => void;
  isActive: boolean;
}) => {
  const wallet = useWallet();
  const connection = useConnection();
  const [verified, setVerified] = useState(false);
  const { requestGatewayToken, gatewayStatus } = useGateway();
  const [webSocketSubscriptionId, setWebSocketSubscriptionId] = useState(-1);
  const [clicked, setClicked] = useState(false);

  const getMintButtonContent = () => {
    if (candyMachine?.state.isSoldOut) {
      return "SOLD OUT";
    } else if (isMinting) {
      return <CircularProgress />;
    } else if (
      candyMachine?.state.isPresale ||
      candyMachine?.state.isWhitelistOnly
    ) {
      return "WHITELIST MINT";
    }

    return "MINT";
  };

  useEffect(() => {
    const mint = async () => {
      await removeAccountChangeListener(
        connection.connection,
        webSocketSubscriptionId
      );
      await onMint();

      setClicked(false);
      setVerified(false);
    };
    if (verified && clicked) {
      mint();
    }
  }, [
    verified,
    clicked,
    connection.connection,
    onMint,
    webSocketSubscriptionId,
  ]);

  const previousGatewayStatus = usePrevious(gatewayStatus);
  useEffect(() => {
    const fromStates = [
      GatewayStatus.NOT_REQUESTED,
      GatewayStatus.REFRESH_TOKEN_REQUIRED,
    ];
    const invalidToStates = [...fromStates, GatewayStatus.UNKNOWN];
    if (
      fromStates.find((state) => previousGatewayStatus === state) &&
      !invalidToStates.find((state) => gatewayStatus === state)
    ) {
      setIsMinting(true);
    }
    console.log("change: ", gatewayStatus);
  }, [setIsMinting, previousGatewayStatus, gatewayStatus]);

  return (
    <CTAButton
      disabled={isMinting || !isActive}
      onClick={async () => {
        if (candyMachine?.state.isActive && candyMachine?.state.gatekeeper) {
          const network =
            candyMachine.state.gatekeeper.gatekeeperNetwork.toBase58();
          if (network === "ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6") {
            if (gatewayStatus === GatewayStatus.ACTIVE) {
              await onMint();
            } else {
              // setIsMinting(true);
              await requestGatewayToken();
              console.log("after: ", gatewayStatus);
            }
          } else if (
            network === "ttib7tuX8PTWPqFsmUFQTj78MbRhUmqxidJRDv4hRRE" ||
            network === "tibePmPaoTgrs929rWpu755EXaxC7M3SthVCf6GzjZt"
          ) {
            setClicked(true);
            const gatewayToken = await findGatewayToken(
              connection.connection,
              wallet.publicKey!,
              candyMachine.state.gatekeeper.gatekeeperNetwork
            );

            if (gatewayToken?.isValid()) {
              await onMint();
            } else {
              window.open(
                `https://verify.encore.fans/?gkNetwork=${network}`,
                "_blank"
              );

              const gatewayTokenAddress =
                await getGatewayTokenAddressForOwnerAndGatekeeperNetwork(
                  wallet.publicKey!,
                  candyMachine.state.gatekeeper.gatekeeperNetwork
                );

              setWebSocketSubscriptionId(
                onGatewayTokenChange(
                  connection.connection,
                  gatewayTokenAddress,
                  () => setVerified(true),
                  "confirmed"
                )
              );
            }
          } else {
            setClicked(false);
            throw new Error(`Unknown Gatekeeper Network: ${network}`);
          }
        } else {
          await onMint();
          setClicked(false);
        }
      }}
      variant="contained"
    >
      {getMintButtonContent()}
    </CTAButton>
  );
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// import styled from 'styled-components';
// import Button from '@material-ui/core/Button';
// import { CandyMachineAccount } from './candy-machine';

// import { CircularProgress } from '@material-ui/core';
// import { GatewayStatus, useGateway } from '@civic/solana-gateway-react';
// import { useEffect, useState } from 'react';
// import { whitelistSettings, publicSaleSettings, mintPanic } from './userSettings';
// import { toDate }  from './utils'

// export const CTAButton = styled(Button)`

//   width: 100%;
//   height: 60px;
//   margin-top: 10px;
//   margin-bottom: 5px;
//   background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
//   color: white;
//   font-size: 16px;
//   font-weight: bold;
// `; // add your styles here

// export const MintButton = ({
//   onMint,
//   candyMachine,

//   isMinting,

// }: {
//   onMint: () => Promise<void>;
//   candyMachine: CandyMachineAccount | undefined;

//   isMinting: boolean;

// }) => {
//   const { requestGatewayToken, gatewayStatus } = useGateway();
//   const [clicked, setClicked] = useState(false);
//   const whitelistStartDate = toDate(whitelistSettings.startDate)?.getTime();
//   const whitelistEndDate = toDate(whitelistSettings.endDate)?.getTime();
//   const publicMintStart = toDate(publicSaleSettings.startDate)?.getTime();
//   const publicMintEnd = toDate(publicSaleSettings.endDate)?.getTime();

//   function whiteListSaleCheck() {
//     if (whitelistSettings.enabled && (whitelistStartDate && whitelistEndDate ) && Date.now() > whitelistStartDate && Date.now() < whitelistEndDate) {

//       return true
//     } else {

//       return false
//     }
//   }

//   let WhitelistMintActive = whiteListSaleCheck()
//   console.log('is Whitelist Sale Active? ' + whiteListSaleCheck())

//   function publicSaleCheck() {

//     if (publicMintStart && publicMintEnd){
//       if(Date.now() > publicMintStart && Date.now() < publicMintEnd){
//         return true
//       } else {
//         return false
//       }
//     }
//     else if (publicMintStart) {
//       if (Date.now() > publicMintStart){
//         return true
//       } else {
//         return false
//       }

//     }

//   }

//   let PublicMintActive = publicSaleCheck()

//   console.log('is public sale live? '+ publicSaleCheck())

//   console.log(candyMachine?.state.isSoldOut, isMinting, (WhitelistMintActive || PublicMintActive) ,!candyMachine?.state.isActive)

//   useEffect(() => {
//     if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
//       console.log('Minting');
//       onMint();
//       setClicked(false);
//     }
//   }, [gatewayStatus, clicked, setClicked, onMint]);
//   return (
//     <CTAButton
//       className='minting-button'
//       disabled={
//         candyMachine?.state.isSoldOut ||
//         isMinting ||
//         mintPanic.enabled ||
//         !(WhitelistMintActive || PublicMintActive)

//       }
//       onClick={async () => {
//         setClicked(true);
//         if (candyMachine?.state.isActive && candyMachine?.state.gatekeeper) {
//           console.log('gatekeeper active')
//           if (gatewayStatus === GatewayStatus.ACTIVE) {
//             console.log(gatewayStatus + GatewayStatus.ACTIVE)
//             setClicked(true);
//           } else {
//             console.log('requeting token')
//              let token = await requestGatewayToken();
//             console.log(token);
//           }
//         } else {
//           await onMint();
//           setClicked(false);
//         }
//       }}
//       variant="contained"
//     >
//       <div className='mint-button-text'>
//       {candyMachine?.state.isSoldOut ? (
//         'SOLD OUT'
//       ) : isMinting ? (
//         <CircularProgress />

//       ) : mintPanic.enabled ? (

//         'Mint Paused'

//       ) :  (
//         'MINT'
//       )}
//       </div>
//     </CTAButton>
//   );
// };
