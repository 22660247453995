import React from "react";
//import "./App.css";
import { useMemo, useState, useEffect } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import MainBg from "./images/mbg.jpg";
import userAvatar from "./images/avatar.png";

import rossi_avatar from "./images/rossi_avatar.png";
import prince_avatar from "./images/prince_avatar.png";
import cele_avatar from "./images/cele_avatar.png";
import she_avatar from "./images/she_avatar.png";

import MainImage from "./images/mimage.png";
import MapBg from "./images/peoplebg.png";
import Logo from "./images/logo.png";
import MapImage from "./images/people.png";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
import { Link } from "react-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import "./onepage.css";
import CountDown from "./components/CountDown";
import {
  CrossMintProvider,
  CrossMintButton,
} from "@crossmint/client-sdk-react-ui";
import "@crossmint/client-sdk-react-ui/styles.css";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import styled from "styled-components";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Alert from "@material-ui/lab/Alert";
import ReactGA from "react-ga";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

// const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
//   ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
//   : undefined;
// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
// const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
// const connection = new anchor.web3.Connection(rpcHost);

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);
// const crsmntClientId = process.env.REACT_APP_CROSSMINT_CLIENT_ID || "";
// const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const configureGoogleAnalytics = () => {
    ReactGA.initialize("G-C61X2RPN80");
  };
  useEffect(() => {
    configureGoogleAnalytics();
  }, []);
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  const [isTimeFinished, setIsTimeFinished] = useState(false); //the state for time of countdown to be finished or not

  const timeFinished = () => {
    setIsTimeFinished(true);
  };

  return (
    // <CrossMintProvider clientId={crsmntClientId}>
    <main>
      <div id="main">
        <Navbar className="nav-bar" collapseOnSelect expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="home">
                    Home{" "}
                  </Link>
                </Nav.Link>
                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="CoW">
                    $CoW
                  </Link>
                </Nav.Link>
                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="Airdrop">
                    Airdrop{" "}
                  </Link>
                </Nav.Link>

                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="collection">
                    Collection
                  </Link>
                </Nav.Link>
                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="roadmap">
                    Roadmap
                  </Link>
                </Nav.Link>
                <Nav.Link className="nav-bar-li">
                  <Link activeClass="active" to="faq">
                    FAQ
                  </Link>
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link
                  className="nav-bar-li-icon"
                  target="_blank"
                  href="https://www.instagram.com/cultureofwomen_cow/"
                >
                  <FaInstagram />
                </Nav.Link>
                <Nav.Link
                  className="nav-bar-li-icon"
                  target="_blank"
                  href="https://twitter.com/CultureOfWomen"
                >
                  <FaTwitter />
                </Nav.Link>
                <Nav.Link
                  className="nav-bar-li-icon"
                  target="_blank"
                  href="https://discord.com/invite/wxaR8J52bv"
                >
                  <SiDiscord />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="main-container-front" id="home">
        <img src={MainBg} alt="main-big-background-img" className="main-bg" />

        <div className="theConnectButton">
          {isTimeFinished && (
            <div className="WalletInfoInner">
              <h2 style={{ textAlign: "center" }}>
                CoW Women of Today NFT - SALE IS NOW LIVE!
                <br />
                Mint for only 2.0 SOL!
                <br />
                <br />
                IMP: CoW Women of Today WILL BE LISTED ON MAGIC EDEN SOON!
                <br />
                <br />
                <h1 style={{ textAlign: "center", color: "red" }}>
                  SUPER MEGA EXCLUSIVE DEAL: <br />{" "}
                </h1>
                <h5 style={{ textAlign: "center", color: "red" }}>
                  Buy 1 NFT get 10M $CoW <br />
                  Buy 3 NFTs get 50M BONUS $CoW <br />
                  Buy 5 NFTs get 100M BONUS $CoW <br />
                  Buy 10 NFTs get 500M BONUS $CoW <br />
                  <br /> Remember: You can use $CoW tokens to purchase amazing
                  partnet project NFTs!
                </h5>
              </h2>

              <div>
                <ThemeProvider theme={theme}>
                  <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                      <WalletDialogProvider>
                        <Minter
                          candyMachineId={candyMachineId}
                          connection={connection}
                          // startDate={startDateSeed}
                          txTimeout={txTimeout}
                          network={network}
                          rpcHost={rpcHost}
                          error={error}
                        />
                      </WalletDialogProvider>
                    </WalletProvider>
                  </ConnectionProvider>
                </ThemeProvider>
              </div>
            </div>
          )}
        </div>

        <div className="theConnectButton"></div>
        <div className="count-down-section">
          <CountDown timeFinished={timeFinished} />
        </div>

        <div className="main-image main-image-contianer">
          <img src={MainImage} alt="main-women" />
          <div className="m-text">
            <iframe
              className="main-video"
              src="https://www.youtube.com/embed/aHVh591NoGY"
              title="YouTube video player"
              //frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              //allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <section className="section-collection" id="collection">
        <Container>
          <h1 className="section-heading">Collection</h1>
          <Row>
            <Col className="d-none d-md-block" xs={12} sm={12} md={6} xl={6}>
              <div className="girl-image-cont">
                <img src={MapImage} alt="collection-girls" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <div className="collection-content">
                <div className="map-text">
                  <p className="colection-text">
                    A collection of 10k unique NFTs to celebrate the culture of
                    women from around the globe on various blockchains. Each NFT
                    contains a computer generated drawing of a woman, whose
                    details were painted with a digital brush. Each of our NFT’s
                    are the rarest combination of different cultural elements
                    based on strong research expertise and all possible
                    generations of female beauty. We studied legends, tales and
                    history of 195 countries, more than 250 national groups and
                    implemented the most interesting elements in our art
                    collection. “Culture of Women” is going to add historical
                    elements to the NFT world and thus create a beautiful
                    combination of the past and future.
                  </p>
                </div>
                <strong>
                  NFT Collection Culture of Women- Edition 1 is the first 500
                  limited edition on Solana
                </strong>
                <a
                  className="card-link collection-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://solscan.io/collection/4d709e13cfe94891eb648eaf398de56eb0bcc1388ccda645e05ff59f9d0d9d9b"
                >
                  Check our Collection
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="main-container collection-main-container" id="collection">
      <img src={MapBg} className="main-bg" alt="collection-section-bg" />
      <div className="main-image second-main-image">
        <img src={MapImage} alt="collection-girls" />
        <h4 className="colection-title">The Collection</h4>
        <div className="map-text">
          <p className="colection-text">
            A collection of 10k unique NFTs to celebrate the culture of women
            from around the globe on various blockchains. Each NFT contains a
            computer generated drawing of a woman, whose details were painted
            with a digital brush. Each of our NFT’s are the rarest combination
            of different cultural elements based on strong research expertise
            and all possible generations of female beauty. We studied legends,
            tales and history of 195 countries, more than 250 national groups
            and implemented the most interesting elements in our art
            collection. “Culture of Women” is going to add historical elements
            to the NFT world and thus create a beautiful combination of the
            past and future.
          </p>
        </div>
        <div className='bottom-content'>
        <strong>NFT Collection Culture of Women- Edition 1 is the first 500 limited edition on Solana</strong>
        <a 
        className="card-link collection" 
        target="_blank" 
        rel="noreferrer"
        href="https://solscan.io/collection/4d709e13cfe94891eb648eaf398de56eb0bcc1388ccda645e05ff59f9d0d9d9b">
         Check our Collection</a>
        </div>
        </div>
    </section> */}
      <div className="purple-container" id="roadmap">
        <Container>
          <h1 className="section-heading">Roadmap</h1>

          <Row>
            <Col className="roadmap-col" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">$CoW Token launched!</h3>
                <p className="rm-content-text completed">$Cow Shop Opened!</p>
                <p className="rm-content-text non-completed">
                  Tons of $CoW token utility coming soon!
                </p>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading">1st December 2021</h3>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="roadmap-col" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">
                  $CoW Token Airdrop Started!
                </h3>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading">5th December 2021</h3>
                <p className="rm-content-text completed">
                  Over 30,000 people registered for Airdrop
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="roadmap-col" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">
                  CoW NFT - Edition 1 Launched!{" "}
                </h3>
                <p className="rm-content-text completed">Presale Sold OUT!</p>
                <p className="rm-content-text completed">
                  Only 500 NFTs released in this Edition
                </p>
                <p className="rm-content-text completed">
                  Massive marketing campaign started!
                </p>
                <p className="rm-content-text completed">
                  20,000 Twitter followers reached!
                </p>
                <p className="rm-content-text completed">
                  15,000 Dsicord members reached!
                </p>
                <p className="rm-content-text completed">
                  $Cow Token affiliate program launched!
                </p>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading">17th December 2021</h3>
              </div>
            </Col>

            <Col className="roadmap-col " xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">CoW NFT - Space Edition!</h3>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading">Sold Out!</h3>
                <p className="rm-content-text completed">
                  Only 400 space women released!
                </p>
                <p className="rm-content-text completed">
                  🇺🇦 Donation to help Ukraine started! Glory to Ukraine! 🇺🇦
                </p>
                <p className="rm-content-text completed">
                  Owners of Edition 1 got 15% discount!
                </p>
              </div>
            </Col>

            <Col className="roadmap-col" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">CoW NFT - More editions</h3>
                <p className="rm-content-text non-completed">
                  Over 9000 NFTs will be relased in various future editions
                </p>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading">Comming soon!</h3>
              </div>
            </Col>

            <Col className="roadmap-col" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">Sale of 5000 NFTs!</h3>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading"></h3>
                <p className="rm-content-text non-completed">
                  1 x Louis Vuitton Bag will be unlocked
                </p>
                <p className="rm-content-text non-completed">
                  5 x MacBook will be unlocked
                </p>
                <p className="rm-content-text non-completed">
                  Liquidity will be added to the Raydium Swap pool MONTHLY!
                </p>
                <p className="rm-content-text non-completed">
                  200 gaming cards will be unlocked!
                </p>
                <p className="rm-content-text non-completed">
                  $Cow Token merch comming soon!
                </p>
              </div>
            </Col>

            <Col className="roadmap-col " xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">Sale of 6000 NFTs!</h3>
                <p className="rm-content-text non-completed">
                  3 x iPhone 13 will be unlocked
                </p>
                <p className="rm-content-text non-completed">
                  5 x PS5 will be unlocked
                </p>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading"></h3>
              </div>
            </Col>

            <Col className="roadmap-col final-goal" xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content right">
                <h3 className="rm-content-heading">Sale of 9000 NFTs!</h3>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} xl={6}>
              <div className="roadmap-content left">
                <h3 className="rm-content-heading"></h3>
                <p className="rm-content-text non-completed">
                  NFT winners will get their NFTs!
                </p>
                <p className="rm-content-text non-completed">
                  A huge secrect surprise for all holders will be unlocked! ;)
                </p>
              </div>
            </Col>
          </Row>
          <div className="goal-desc-container">
            <strong className="rm-content-text">
              15% of the proceeds from all primary and secondary sales will be
              used to buy out the NFT "Culture of Women", that will be locked in
              a vault for 1 year from the date of purchase.
              <br />
              15% of the proceeds from all primary and secondary sales will go
              to a charity chosen by community voting.
            </strong>
          </div>
        </Container>
      </div>
      <section className="team-section">
        <Container>
          <h1 className="section-heading hdng-light">Meet the Founder</h1>
          <div className="team-cards-row">
            <div className="team-card">
              <div className="card-avatar">
                <img src={userAvatar} alt="user-avatar" />
              </div>
              <br />
              <h4 className="username">Anna Chukhraienko</h4>
              <strong className="designation">Founder & CEO</strong>
              <p className="team-intro">
                Anna tried a variety of professions, including entrepreneur,
                CEO, interpreter, writer and NFT creator. In her early 30s, she
                started writing and publishing her book using innovative
                blockchain technology. Inspired by the power of the blockchain,
                Anna started an NFT project "Culture of Women" to celebrate
                culture, equality and diversity and to contribute towards a
                better future. The multicultural background, diverse skills and
                innovative views helped her create an NFT project with great
                potential.
                <br />
                DISCORD: cultureofwomen#0509
              </p>
              <div className="team-icons">
                <a href="https://twitter.com/chukhraienko">
                  <FaTwitter />
                </a>
                <a href="https://discord.gg/cultureofwomen">
                  <SiDiscord />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="green-container" id="CoW">
        <Container>
          <h1 className="section-title">$CoW Tokens!</h1>
          <p className="section-text">
            • What is $Cow Token?
            <br /> $CoW Token is a utility token that will be used in the
            Culture Of Women platform. We are not just launching an NFT but this
            is a Platform! We will host fun games, giveaways, contest and
            parties. To enter these fun adventures users will have to use the
            $CoW tokens. We have also launched $CoW-Shop on our discord channel.
            You can go there and purchase amazing products using $CoW tokens. We
            will also do survey and voting for adding new items in our store.
            Lots of other fun utility is in works.
            <br />
            <br />
            • TOKEN DISTRIBUTION AND TOKENOMICS MODEL
            <br /> There are many cool and fun ways to earn $CoW tokens!
            <br />
            The best way to get lots of tokens is by purchasing the Culture of
            Women NFTs:
            <br />`{">"}` The First 1,000 NFT buyers will get 2,000,000 $CoW
            tokens each!!!
            <br />`{">"}` The next 2,000 NFT buyers will get 1,500,000 $CoW
            tokens each!!!
            <br />`{">"}` The next 2,000 NFT buyers will get 1,000,000 $CoW
            tokens each!!!
            <br />`{">"}` The next 2,000 NFT buyers will get 500,000 $CoW tokens
            each!!!
            <br />`{">"}` The last 3,000 NFT buyers will get 100,000 $CoW tokens
            each!!!
            <br />
            <br />
            • Official $CoW utility token has launched on Solana! <br />
            Official $CoW token address:
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://solscan.io/token/CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu"
            >
              https://solscan.io/token/CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu{" "}
            </a>
            <br />
            <br />• Official $CoW liquidity pool has been launched on Raydium!{" "}
            <br />
            Official $CoW liquidity pool address:
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu&outputAmount=0&fixed=in"
            >
              https://raydium.io/swap/?inputCurrency=sol&outputCurrency=CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu&outputAmount=0&fixed=in{" "}
            </a>{" "}
            <br />
            <br />
            <br />
            • Providing liquidity via our profits! <br />
            5% of our profit will be used to provide liquidity to the token on
            the Raydium Swap pool! We will do this every month!
          </p>
        </Container>
      </div>

      <div className="section-container bg-blue" id="Airdrop">
        <Container>
          <h1 className="section-title font-white">Airdrop</h1>
          <p className="section-text font-white">
            <Row>
              <Col md={6} sm={6}>
                <div className="square-card">
                  <h3 className="card-heading">Get $CoW Airdrop</h3>
                  <p className="card-desc">
                    To get 1000 $CoW. Please click the button below and fill the
                    google form.
                  </p>
                  <a
                    className="card-link"
                    target="_blank"
                    href="https://forms.gle/uGqtMo2ytZBwQeKDA"
                  >
                    Get Now!
                  </a>
                </div>
              </Col>
              <Col md={6} sm={6}>
                <div className="square-card">
                  <h3 className="card-heading">Redeem Airdrop</h3>
                  <p className="card-desc">
                    In the "Culture of Women - Airdrop Redeem" google sheet,
                    find your Solana address then click on the corresponding
                    link.
                  </p>
                  <a
                    className="card-link"
                    target="_blank"
                    href="https://bit.ly/3IdRk3z"
                  >
                    Redeem
                  </a>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <br />

            <p>
              NOTE: Please give us a few days after filling your airdrop form.
            </p>
            <p>
              {" "}
              We have recieved over 35k entries so please submit your airdrop
              application and give us some time to process it all. Thank you for
              your tremendous support!
            </p>
          </p>
        </Container>
      </div>

      <div className="golden-container" id="faq">
        <Container>
          <h1 className="section-heading">FAQ</h1>
          <p className="faq-text">
            • What is Culture of Women?
            <br /> Culture of Women is a collection of 10 000 NFTs on various
            blockchains. Each NFT contains all of the different female beauties
            and a wide variety of cultural elements. As an example, 1 Culture of
            Women will wear an Arikara headdress, Thai dress, Chinese earring,
            French necklace, and Arabic makeup. Yes, we are creating a new
            generation of cultural diversity!
            <br />
            <br />
            • Who is on the team? <br />
            The creator is Anna Chukhraienko, who started with the first book
            publishing as NFT. She is working with a very talented team of young
            artists, best advisers in the space, social media marketers,
            incredible developers and a highly motivated moderator. We are
            passionate about NFT’s, Blockchain technology and the potential in
            this industry. We love the energy and openness of crypto and want to
            make a positive and fun environment which also helps improve our
            world. <br />
            <br />
            • Are you an all-woman team?
            <br /> The creator and artist are female, but the idea of the
            project was never to limit collaboration to female representatives
            only. We enjoy working with men all over the world.
            <br />
            <br />
            • What is the SOL mint price of CoW NFT Limited Edition 1? <br />
            0.7 SOL
            <br />
            <br />
            • Do you use the rerarity system?
            <br /> Yes, we do. We’ve designed the traits that can be generated
            exceptionally rare. <br />
            <br />
            • What do I get after the purchase? <br />
            You will be the owner of the Culture of Women NFT on Solana
            blockchain. You can choose to list on the secondary market or stay
            with us for longer! All owners of the first edition will be
            automatically taken to the presale and giveaway list of the 2nd
            Edition of Culture of Women on a new blockchain. <br />
            <br />• Why is Culture of Women the next big project?
            <br />
            Our community is growing at a rapid rate and we will continue to
            cultivate, care and refine the project for a long-time! After the
            mint we are going to repurchase Culture of Women NFTs using 15 % of
            the profit. All the Culture of Women that we buy back will be locked
            in a vault for 1 year from the date of purchase. This will ensure a
            supply shock, helping us stabilize or even increase the floor price
            of Culture of Women NFT.
          </p>
        </Container>
      </div>
      <div className="footer-container">
        <Container fluid>
          <Row className="row-footer">
            <Col md={4} sm={3}>
              <img className="logo-img" src={Logo} alt="footer-logo" />
            </Col>
            <Col md={4} sm={4}>
              <ul className="footer-list">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/cultureofwomen_cow/"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/CultureOfWomen"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://discord.com/invite/wxaR8J52bv"
                  >
                    {" "}
                    <SiDiscord />{" "}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={4} sm={4} className="copyright">
              <p>© 2021</p>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
    // </CrossMintProvider>
  );
};

export default App;
