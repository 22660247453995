import React from "react";
import Countdown from "react-countdown";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./countDown.module.css";
const CountDown = ({ timeFinished }) => {
  // "December 17, 2021 18:00:0"
  const futureDate = new Date("Aug 1, 2022 21:00:00 GMT").getTime();
  const now = new Date().getTime();
  const distance = futureDate - now;

  const Completionist = () => {
    timeFinished();
    return <></>;
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          <h2 style={{ textAlign: "center" }}>
            CoW - Women of Today <br></ br>
            Minting begins August 1, 2022 21:00:00 GMT
          </h2>
          <div className={classes.CountDownLayout}>
            <CircularProgressbar
              className={`${classes.progressBarCircle} dayCircle`}
              value={(days / 365) * 100}
              strokeWidth={20}
              text={`Days:${days}`}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
            
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
            
                // Text size
                textSize: '16px',
            
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
            
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
            
                // Colors
                pathColor: `rgba(62, 152, 199, ${(days / 365) * 100})`,
                textColor: 'black',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            />
            <CircularProgressbar
              className={`${classes.progressBarCircle} HourCircle`}
              value={(hours / 24) * 100}
              strokeWidth={13}
              text={`Hours:${hours}`}
              
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
            
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
            
                // Text size
                textSize: '16px',
            
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
            
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
            
                // Colors
                pathColor: `rgba(62, 152, 199, ${(hours / 24) * 100})`,
                textColor: 'black',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            />
            <CircularProgressbar
              className={`${classes.progressBarCircle} MinutesCircle`}
              value={(minutes / 60) * 100}
              strokeWidth={13}
              text={`Minutes:${minutes}`}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
            
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
            
                // Text size
                textSize: '16px',
            
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
            
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
            
                // Colors
                pathColor: `rgba(62, 152, 199, ${(minutes / 60) * 100})`,
                textColor: 'black',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            />
            <CircularProgressbar
              className={`${classes.progressBarCircle} SecondsCircle`}
              value={(seconds / 60) * 100}
              strokeWidth={13}
              text={`Seconds:${seconds}`}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
            
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
            
                // Text size
                textSize: '16px',
            
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
            
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
            
                // Colors
                pathColor: `rgba(62, 152, 199, ${(seconds / 60) * 100})`,
                textColor: 'black',
                trailColor: 'brown',
                backgroundColor: 'pink',
              })}
            />
          </div>
        </span>
      );
    }
  };
  return (
    <div className={`${classes.countDownContainer} countDownContainer-all`}>
      <Countdown date={Date.now() + distance} renderer={renderer} />
    </div>
  );
};

export default CountDown;
